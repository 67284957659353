import React, {FC, useState } from "react";
import "./FrequentQuestions.css";
import FQuestion from "../question-entry/FQuestion";
import questions from '../../data/FrequentQuestionsData';

const FrequentQuestions: FC = () => {
                                   
                                    const [expandedQuestion, setExpandedQuestion] = useState<number>(-1);

                                    return(
                                        <section className="frequent-question-section">
                                            <h1 className="frequent-question-section__title">Preguntas frecuentes</h1>
                                            <div  className="frequent-question-section__questions">
                                                { questions.map( (question, index) => <FQuestion 
                                                                                        indexUpdater={() => setExpandedQuestion(index) } 
                                                                                        question={question.question}  
                                                                                        answer={ question.answer }
                                                                                        isExpanded={index === expandedQuestion} 
                                                                                        key={index} />) }
                                            </div>
                                        </section>
                                        );
                                     };
export default FrequentQuestions; 