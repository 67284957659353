import React, { FC } from 'react';
import "./WebPageOwnDomain.css";
import TellToExpertButton from '../tell-to-expert-button/TellToExpertButton';
import checkIcon from "../../assets/icons/check_icon.svg";
import EclinicAquamarineRoundedButton from '../eclinic-aquamarine-rounded-button/EclinicAquamarineRoundedButtonComponent';
import navigateToDrHelpWhatsapp from '../utils/raw_navigation';


const WebPageOwnDomain: FC = () => <section className="web-page-own-domain-advise-container">
                                        <div className="web-page-own-domain-advise-container__advertisement">
                                            <div className="web-page-own-domain-advise-container__advertisement-icon-container">
                                                <img 
                                                    className='web-page-own-domain-advise-container__advertisement-icon'
                                                    alt={"ícono de invitacon a tener su propio dominio"}
                                                    src={checkIcon}
                                                    />
                                            </div>
                                            <div className="web-page-own-domain-advise-container__info">
                                                <h2 className="web-page-own-domain-advise-container__title">Página web con dominio propio</h2>
                                                <p className="web-page-own-domain-advise-container__text">¿Necesitas una página especializada en el sector salud? <br/> Tenemos opciones personalizables, con agenda virtual.</p>
                                            </div>

                                        </div>
                                        <div className="web-page-own-domain-to_expert-button-conatainer">
                                            <EclinicAquamarineRoundedButton text="AGENDAR DEMO" callback={ navigateToDrHelpWhatsapp }/>
                                        </div>
                                   </section>;

export default WebPageOwnDomain; 
