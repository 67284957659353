import React, { FC } from 'react';
import logoEclinic from '../../assets/branch/logo_eclinic_mono.png';
import './Footer.css'
import { mdiFacebook, mdiInstagram } from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';

const Footer: FC = () => <footer className="eclinic-footer">
                            <div className='footer-container'>
                            <div className='footer-logo-container'>
                                <div className="footer-logo__image-wrapper">
                                    <Link to="/">
                                        <img className='footer__logo' src={logoEclinic} alt="Logo de eclinic" />
                                    </Link>  
                                </div>
                                <p>Gestión clínica hecha simple</p>  
                                <div className="footer-logo__icons-wrapper">
                                    <a href="https://www.facebook.com/eclinicdoctor">
                                        <Icon 
                                        path={mdiFacebook}
                                        title="eClicni menu"
                                        size={1.5}
                                        horizontal
                                        vertical
                                        rotate={180}
                                        color="white"/>
                                    </a>
                                   <a href="https://www.instagram.com/eclinicdoctor/">
                                    <Icon 
                                        path={mdiInstagram}
                                        title="eClicni menu"
                                        size={1.5}
                                        horizontal
                                        vertical
                                        rotate={180}
                                        color="white"/>
                                   </a>
                                   
                                </div>   
                            </div>
                            <div className="footer_nav__companies-container">
                            </div>
                            <div className="footer_nav__modules-container">
                            </div>
                            </div>
                            <div className="footer-divider"/>
                            <p className="copyRigth">Doctorhelp! © 2021. Todos los derechos reservados. </p>
                        </footer> 

export default Footer;