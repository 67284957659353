import React, { FC, } from 'react';
import AdvantagesEntry from '../advantage-entry/AdvantageEntry';
import './EClinicAdvantage.css';
import headerVideo from "../../assets/videos/eclinic_video_final.webm";
import Feature from "../../models/Feature";
import { useBreakPoint }  from '../../hooks/BreakPoint';
import { Link } from 'react-router-dom';
import EclinicAquamarineRoundedButton from '../eclinic-aquamarine-rounded-button/EclinicAquamarineRoundedButtonComponent';
import navigateToDrHelpWhatsapp from '../utils/raw_navigation';


const EClinicAdvantage: FC = () => {         
                                   const useBreakpointQuery = useBreakPoint();

                                   let newLine = useBreakpointQuery["md"]?  <br/>: " "
                    
                                   const features: Feature[] = [
                                        { title: 'TRABAJE DESDE CUALQUIER LUGAR', featureDescription: <p>Organice la información clínica de sus pacientes, ingresando{newLine}desde cualquier dispositivo con acceso a internet</p>},
                                        { title: 'CUMPLA CON LA NORMATIVIDAD', featureDescription: <p>Atienda a sus pacientes siguiendo la normativa vigente, para{newLine}el manejo de la historia clínica electrónica y Telemedicina.</p> },
                                        { title: 'CONFIGURE SEGÚN SU ESPECIALIDAD', featureDescription: <p>Personalice el formato de historia según su práctica clínica{newLine}con nuestra ayuda. Adaptable para todas las profesiones.</p> },
                                        { title: 'MANTENGA LA IMAGEN DE SU INSTITUCION', featureDescription: <p>Genere documentos con el logo de su clínica, incluyendo{newLine}prescripciones, exámenes e incapacidades.</p>
                                   }
                                   ];
     
                                    return (
                                            <>
                                                  <section className='eclinic-advantages-section-container'>
                                                      <div className='eclinic-advantages-section'>     
                                                      <div className='eclinic-advantages-section__img-container'>
                                                            <video 
                                                                 className='eclinic-advantages-section__img'
                                                                 src={headerVideo}
                                                                 autoPlay
                                                                 muted
                                                                 loop
                                                            />
                                                       </div>
                                                        <div 
                                                            className='eclinic-advantages-section__advages-features'>
                                                                 { features.map( (feature, index) => <AdvantagesEntry title={feature.title} key={index}>
                                                                                     {feature.featureDescription}
                                                                                 </AdvantagesEntry>  ) } 
                                                            </div>
                                                       </div> 
                                                       <div className='eclinic-advantages-section_button'>
                                                            <EclinicAquamarineRoundedButton text='SOLITAR INFORMACION' callback= { navigateToDrHelpWhatsapp }>
                                                             </EclinicAquamarineRoundedButton> 
                                                       </div>  
                                                  </section>
                                            </>      
                                    );
                                   } 
                                  

export default EClinicAdvantage;