import React, { FC } from 'react';
import './AdvantageEntry.css';
import ckeckIcon from '../../assets/icons/check_icon_aquamarine.png';


const AdvantagesEntry: FC<{title: string}> = ({ title, children }) => <article className='advantages-feature-container'>
                                                                <div  className='advantages-feature__icon-container' >
                                                                   <img className="advantages-feature__icon"  src={ckeckIcon} alt="Feature check"/>     
                                                                </div>
                                                                 <h3 className='advantages-feature__title'>{title}</h3>
                                                                 <div className='advantages-feature__text'>{children}</div>  
                                                            </article>

export default AdvantagesEntry;