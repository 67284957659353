import React, { FC } from 'react';
import ProductServiceFeature from '../product-service-feature/ProductServiceFeature';
import "./ProductServiceItem.css";

interface PropArg {
    description: string
    serviceTitle: string
    icon?: string,
    servicesFeatures: string[]
}

const ProductServiceItem: FC<PropArg> = ({description, serviceTitle, servicesFeatures, children}) => 
                                    <article className="product-service__item-container">
                                        <div className='product-service__round-container' >
                                            { children } 
                                        </div>
                                        <div className="solid-divider"/>
                                        <h3 className='service-container__item-title'>{serviceTitle}</h3>      
                                        <p className='service-container__item-description'>{description}</p>
                                        <ProductServiceFeature features={servicesFeatures}/> 
                                    </article>;

export default ProductServiceItem;