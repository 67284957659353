import React, { FC } from 'react';
import './EClinicDescription.css';
import FeatureCheck from '../feature-check/FeatureCheck';
import marketing from '../../assets/img/marketin_ eclini.svg';
import marketPlaceGif from '../../assets/videos/marketplace_eclinic_final.webm';
import EclinicAquamarineRoundedButton from '../eclinic-aquamarine-rounded-button/EclinicAquamarineRoundedButtonComponent';
import navigateToDrHelpWhatsapp from '../utils/raw_navigation';

const featuresChecks: string[] = ['Acesso web sin instalaciones',`Alojamiento en la nube`,
                                  'Encriptación de la información','Copias de seguridad', 'Multidispositivo'];

const featuresMarketingChecks: string[] = ['Programe recordatorios personalizados.',`Envie mensajes automáticos por e-mail y SMS`,
                                  'Haga seguimiento a sus campañas de mercadeo.','Brinde promociones a sus pacientes, por motivo de cumpleaños, por temporada o planes de referidos.', 
                                  'Recuérdele la toma de medicamentos a sus pacientes y aumente la adherencia al tratamiento.'];                                  

const MarketplacePremiumAccessChecks: string[] = ['Obtenga un perfil digital',`Aumente su visibilidad`,
                                          'Atraiga nuevos pacientes','Reciba reviews y opiniones', 'Publique fotos y videos de su clínica', 
                                           'Publicidad a través de aliados'];                                  


const EClinicDescription: FC = () => <section className='eclinic-description-section'>
                                       <div className="eclinic-description-3">
                                          <div className="eclinic-description-3-container">
                                             <div className="eclinic-description-3-container__description">
                                                <h2 className="eclinic-description-3-container__title" >Desde la primera atención  hasta la fidelización </h2>
                                                <p className="eclinic-description-3-container__text">Fortalezca las relaciones con sus pacientes gracias a las funciones de marketing de eClinic. </p>
                                                <div className="eclinic-description-3_features">
                                                   { featuresMarketingChecks.map( feature => <FeatureCheck feature={feature} key={feature}/> ) }
                                                </div>
                                             </div>
                                             <div className="eclinic-description-3-container__container">
                                                <img className="eclinic-description-2-container__img" src={marketing} alt="Other systems integrations"></img>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="eclinic-description-4">
                                          <div className="eclinic-description-4-container"> 
                                             <div className="eclinic-description-3-container__description">
                                                <h2 className="eclinic-description-3-container__title" >Acceso Premium a <br /> Marketplace de salud</h2>
                                                <p className="eclinic-description-3-container__text">Conéctese con más pacientes y sea parte de un ecosistema de salud en crecimiento. Beneficio exclusivo para clientes eClinic.</p>
                                                <div className="eclinic-description-3_features">
                                                   { MarketplacePremiumAccessChecks.map( feature => <FeatureCheck feature={feature} key={feature}/> ) }
                                                </div>
                                                <div id='marketplace-premium_button-container'>
                                                   <EclinicAquamarineRoundedButton text="CONOCER MAS" callback={ navigateToDrHelpWhatsapp }/>
                                                </div>
                                             </div>
                                             <div className="eclinic-description-3-container__container">
                                                <video className="eclinic-description-3__marketplace-video" 
                                                      src={marketPlaceGif} autoPlay muted loop>
                                          
                                                </video>
                                             </div>
                                          </div>
                                          
                                       </div>
                                     </section>

export default EClinicDescription;                                     
