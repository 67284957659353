import React, {FC } from "react";
import "./WhatsappContactIcon.css";
import whatsappIcon from "../../assets/icons/whatsapp_icon.webp";
import { drHelpWhatsappPhoneNumber } from "../../data/Constants";


const WhatsappContactIcon: FC = () => <article 
                                        className="whatsapp-contact-icon_container">
                                            <a href={`https://api.whatsapp.com/send?phone=${drHelpWhatsappPhoneNumber}`} 
                                               target="_blank">
                                                    <img src={whatsappIcon} 
                                                        alt="Whatsapp icon for contact with company"
                                                        className="whatsapp-contact-icon__img" />                             
                                            </a>
                                      </article>;

export default WhatsappContactIcon;     