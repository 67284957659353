import React, { FC } from 'react';
import checkIcon from '../../assets/icons/check_icon_aquamarine.png';
import './ProductServiceFeature.css';

interface ProductServiceFeatureArgs {
    features: string[]
}

const ProductServiceFeature: FC<ProductServiceFeatureArgs> = ({features}) => <div className="product-service-feature__container">
                                                                            { features.map( (feature, index) => 
                                                                                <div className='product-service-feature__entry' key={index}>
                                                                                      <img className='product-service-feature__icon' src={checkIcon} alt="Feature check icon"/> 
                                                                                      <span className="product-service-feature__text">{feature}</span>       
                                                                                </div>
                                                                                ) 
                                                                            } 
                                                                            </div>;

export default ProductServiceFeature;