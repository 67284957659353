import React, { FC } from 'react';
import './EclinicAquamarineRoundedButton.css';
import EclinicAquamarineRoundedButtonArgs from './EclinicAquamarineRoundedButton';

const EclinicAquamarineRoundedButton: FC<EclinicAquamarineRoundedButtonArgs> = ({text, callback}: EclinicAquamarineRoundedButtonArgs) => 

                <div>
                    <button className='eclinic-aquamarine-rounded-button' onClick={callback}>
                        {text}
                    </button>
                </div>;

                                                      
export default EclinicAquamarineRoundedButton; 

