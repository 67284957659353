import FrequentQuestions from "../models/FrequentQuestionsModel";

const questions: FrequentQuestions[] = [
    {
        question: "1. ¿Debo contratar el servicio por un tiempo mínimo? ¿Hay cláusula de permanencia?",
        answer: "Puede contratar el servicio de forma mensual con pago anticipado y retirarse cuando lo desee. Tenga en cuenta que solo podrá acceder a beneficios Premium sin costo adicional (acceso a Marketplace de salud) si ha firmado un contrato a un año con nosotros."
    },
    {
        question: "2. ¿Cuál es el costo mensual del plan y que funcionalidades se incluyen en el servicio?",
        answer: "El costo del plan es de $89.000 mensuales e incluye los módulos de historia clínica electrónica, agendamiento de citas, telemedicina (nuevo), facturación y finanzas, gestión de inventarios, notificaciones y recordatorios."
    },
    {
        question: "3. ¿Necesito instalar alguna herramienta para usar el software de historia clínica?",
        answer: "No necesita instalar ninguna herramienta o plugin. Para acceder a eclinic solo necesitará cualquier dispositivo con acceso a internet. Le proporcionamos los servidores, el almacenamiento y un subdominio de su elección, para que use eClinic ingresando a una dirección web."
    },
    {
        question: "4. En caso de que yo no quiera continuar con el servicio, ¿qué pasaría con la información que tengo almacenada?",
        answer: "Puede solicitar la exportación de historias clínicas a nuestro servicio de soporte técnico, para que posteriormente pueda ser importada por otro proveedor tecnológico."
    },
    {
        question: "5. Puedo personalizar sin costo adicional el logo de mi clínica o consultorio en los Formatos para prescripciones, incapacidades y facturas?",
        answer: "Le ayudemos a configurar el logo de su institución sin costo adicional, para lo cual debe suministrar la imagen correspondiente."
    },
    {
        question: "6. Actualmente uso otro software pero me gustaría cambiarlo, ¿Cómo pueden ayudarme?",
        answer: "Podemos ayudarle a migrar sus historias clínicas desde otro software a nuestro sistema, sin problema."
    }
] 

export default questions;