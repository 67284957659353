import React, { FC, useEffect } from 'react';
import HubspotForm from 'react-hubspot-form';
import "./Contact.css";
import ReactPixel from 'react-facebook-pixel';

const ContactDispatcher: () => void = () => {
    ReactPixel.track( "Contact");
    console.log('Contacto realizado, envio exitoso!')
    
}

const Contact: FC = () => {
    useEffect(() => {
        window.addEventListener('message', handler);
        return () => {
            window.removeEventListener('message', handler);
        }
    }, [])
    
      function handler(event: MessageEvent) {
        if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
            if (event.data.id === 'f2d3568b-74e3-4663-b3f1-93348536090d')
            console.log('True');
            else if (event.data.id === 'f2d3568b-74e3-4663-b3f1-93348536090d')
            console.log('False');
        }
    }
    return (
        <section className='hubspot-form-drhelp'>
             <div className='hubspot-form-container'>
                <HubspotForm
                     portalId='9235125'
                     formId='f2d3568b-74e3-4663-b3f1-93348536090d'
                     onSubmit= { () => ContactDispatcher()}
                     onReady={(form: any) => console.log('Form ready!')}
                     loading={<div>Cargando...</div>}
                                             />
             </div>   
       </section>
    );
} 
                                       
export default Contact;       
