import React, { FC } from 'react';
import CustomerReview from '../customer-reviews/CustomerReview';
import { CustomerReviewPropsModel } from '../../props_models/CustomerReviewPropsModel';
import './CustomerReviewList.css';


const doctorReviews: CustomerReviewPropsModel[] = [
    {
        review: 'eclinic mejoró mi consulta, tengo más orden en mis historia clínica, optimizó mi trabajo ya que tengo todo en un solo lugar, desde inventario hasta radiografías que necesito',
        doctorName: 'Dra. Mónica Paz',
        specialty: 'Periodóncia',
        medicalEntity: 'Boca Innova Dental Group'
    },
    {
        review: 'La aplicación me parece muy completa, puedo tener fotos del proceso de mis pacientes, llevar inventarios, contabilidad, además es muy fácil de manejar y mantengo en orden mis historias.',
        doctorName: 'Dra. Adriana Castro',
        specialty: 'Ortodóncia',
        medicalEntity: 'Propia'
    },
    {
        review: '"El fácil manejo y claridad de la plataforma son claves para posibilitar un óptimo tratamiento terapéutico de los pacientes"',
        doctorName: 'Dra. Paola Santamaria',
        specialty: 'Psicología',
        medicalEntity: 'Consultorio privado'
    },
]

const CustomerReviewList: FC = () => <section className='customer-reviews-container'>
                                       {/*  <h3 className='customer-reviews-container__title'>¿Qué dicen nuestros clientes?</h3> */}
                                        <div className='customer-review__review'>
                                        {
                                          doctorReviews.map( review => <CustomerReview {...review} key={review.doctorName}/> )
                                        }
                                        </div>
                                    </section>; 
                              

export default CustomerReviewList;