import React from 'react';
import './LandingPageHeader.css'

const LandingPageHeader = () => {           
                                       return ( 
                                            <div className='landing-page-head-container'>
                                                <div className='landing-page-head-container_title'>
                                                    <div className='landing-page-head-container_title-text-container'>
                                                        <h1>Digitalice la Gestión de su <span>consultorio.</span></h1> 
                                                    </div>
                                                    <p>eClinic es la solución <b>TODO EN UNO</b> para la atención presencial y virtual de pacientes.</p>
                                                    <div className='landing-page-header_price_container'>
                                                        <p><b id='landing-page-header_price_from'>Desde</b> <b id='landing-page-header_price_text'>$89.000 COP</b></p> 
                                                    </div>                                      
                                                </div>
                                           </div> 
                                        );
                                    }

export default LandingPageHeader;