import React, { FC } from 'react';
import CheckFeatureInterface from '../../props_models/CheckFeatureModel';
import './FeatureCheck.css';
import checkIcon from "../../assets/icons/check_icon_aquamarine.png";

const FeatureCheck: FC<CheckFeatureInterface> = ({feature}) => <div className='feature-container' >
                                                                    <div className="feature-container__check-img-container"> 
                                                                        <img className="feature-container__check-img" src={checkIcon} alt="Feature check"/>                   
                                                                    </div>
                                                                    <p>{feature}</p>
                                                                </div>

export default FeatureCheck;                                                       
