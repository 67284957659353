import React, { FC, useState, useRef } from 'react';
import "./FQuestion.css";
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { CSSTransition } from 'react-transition-group';

const FQuestion: FC<{question: string, answer:string, isExpanded: boolean, indexUpdater: () => void }> = ( {question, indexUpdater, answer,  isExpanded = false} ) => {

    const heightRef = useRef<HTMLDivElement>(null);
    const [ height, setHeight ] = useState<string>(`${heightRef.current?.offsetHeight}px`);
    

    return(
           <article
           
               onClick={() => indexUpdater()} 
               className="question-container" 
               style={{border: isExpanded? ".5px solid rgb(29, 219, 214)": "" }}>
               <div  
               className="question-container_visible-container">
                   <div className='question__icon-container'>
                       <Icon 
                           className='question__icon'
                           path={mdiPlus}
                           title="plus"
                           size={1.75}
                           horizontal
                           vertical
                           rotate={180}
                           color="#19c1ce"/>
                   </div>
                                    
                   <h2 className="question__question" >
                       {question}
                   </h2>
               </div>
                {
                    <CSSTransition
                        in={ isExpanded }
                        timeout={600}
                        classNames="question_anim"
                        onExiting={() => setHeight(`0px`)}
                        onEntering={ () => {
                            setHeight( `${heightRef.current?.offsetHeight}px` );
                        }}
                        unmountOnExit >
                            <div 
                                ref={ heightRef }
                                 className="question-container_answer-container"
                                 >
                                    <p>{ answer }</p>
                            </div>
                    </CSSTransition>   
                }                                                                                                                                                      
           </article>
    );
}

export default FQuestion;