import React, { FC } from 'react';
import './CustomerReview.css';
import { CustomerReviewPropsModel } from '../../props_models/CustomerReviewPropsModel';
import Icon from '@mdi/react';
import { mdiFormatQuoteOpen } from '@mdi/js';

const CustomerReview: FC<CustomerReviewPropsModel> = ({ review, doctorName, specialty, medicalEntity }) => 
                                  <section className='customer-review__item-container'>
                                        <article  className="customer-review">
                                            <Icon 
                                                path={mdiFormatQuoteOpen}
                                                title="Quote profesional"
                                                size={1.5}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#0c3a5b"/>  
                                            <p className="customer-review__text">{ review }</p>
                                            <div className="customer-review__footer">
                                                <p className="customer-review__footer-name">{ doctorName }</p>
                                                <p> <span className="customer-review__footer-specialty">{ specialty }</span> - <span className="customer-review__footer-medical-entity" >{ medicalEntity }</span></p>
                                            </div>
                                        </article>
                                  </section>;

export default CustomerReview;                                  

