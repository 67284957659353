import React, { useEffect } from 'react';
import "./EpaycoResponsePage.css";
import ReactPixel, { Purchase } from 'react-facebook-pixel';
import { mdiStore } from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';


const purchase: Purchase =  {
  /*   content_name: "eclinic plan mi consultorio, pago mensual", */
    currency: "COP",
    value: 89000,
  }
;

const ContactDispatcher: () => void = () => {
    ReactPixel.track( "Purchase",  purchase);
}

const EpaycoResponsePage = () => {
    useEffect( () => {
        ContactDispatcher();     
    }, []);

    return (
        <section className="epayco-response-section">
            <div className='epayco-response__store-icon-container'>
                <Icon className='epayco-response__store-icon'
                     path={mdiStore}
                     title="store icon"
                     size={5}
                     horizontal
                     vertical
                     rotate={180}
                     color="#0c3a5b"/>
             </div>
             <div>
                 <p className="epayco-response__thanks-text">Gracias por su compra</p>
             </div>
             <Link to="/"
                   className="epayco-response__back-button-link" 
             >
                <div className="epayco-response__back-button">
                    <p>Regresar</p>
                </div>
             </Link>
        </section>
    );

}

export default EpaycoResponsePage;