import React, { FC } from 'react';
import LandingPageHeader from '../components/landing-page-header/LandingPageHeader';
import EClinicDescription from '../components/eclinic-description/EClinicDescription';
import EClinicUserPreference from '../components/eclinic-user-preference/EClinicUserPreference';
import CustomerReviewList from '../components/customer-review-list/CustomerReviewList';
import EClinicAdvantage from '../components/eclinic-advantages/EClinicAdvantage';
import ProductServiceFlexList from "../components/eclinic_product-services-flex-list/ProductServicesFlexList"
import { Helmet } from 'react-helmet';
import EclinicAquamarineRoundedButton from '../components/eclinic-aquamarine-rounded-button/EclinicAquamarineRoundedButtonComponent';
import WebPageOwnDomain from '../components/web-page-own-domain/WebPageOwnDomain';
import WhatsappContactIcon from '../components/whatsapp-contact-icon/WhatsappContactIcon';
import navigateToDrHelpWhatsapp from '../components/utils/raw_navigation';

const MainPage: FC = () => {
return ( <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>eClinic - Especialistas. Gestión clínica hecha simple</title>
                <link rel="canonical" href="http://www.doctorhelp.com" />
                <meta name="description" content="eClinic - Especialistas, le permite administrar mejor su clínica o consultorio. Gestione Citas, historia clínica y Telemedicina en un solo lugar."/>
                
            </Helmet>
             <main className='main-content'>
                                <LandingPageHeader />
                                <EClinicAdvantage />
                                <CustomerReviewList />
                                <ProductServiceFlexList />
                                <div  style={{
                                     marginBlockEnd: "36px"
                                }}>
                                   <EclinicAquamarineRoundedButton text="AGENDAR DEMO" callback={ navigateToDrHelpWhatsapp }/>
                                </div>
                                <EClinicDescription />
                                <EClinicUserPreference />
                                <div className="web-page-own-container">
                                   <WebPageOwnDomain />
                                </div>
                                <WhatsappContactIcon/>
                                
                            </main>
   </> );
};

export default MainPage;                            

