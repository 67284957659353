import React, { FC } from 'react';
import EClinicProductAndServicePropModel from '../../props_models/EClinicProducAndServicesPropModel';
import appointments from '../../assets/icons/appointments.png';
import eHistory from '../../assets/icons/clinic_history.png';
import stock from "../../assets/icons/stock.png";
import teleHealt from "../../assets/icons/telehealth.png";
import prescripciones from "../../assets/icons/prescriptions.png";
import billingFacturing from "../../assets/icons/finance.png";
import ProductServiceItem from "./ProductServiceItem";
import "./ProductServiceFlexList.css"
import { Link } from 'react-router-dom';


const servicesDescriptions: EClinicProductAndServicePropModel[] = [
    {
          serviceTitle: 'Historia electrónica',
          description: 'Obtenga una historia clínica amigable y configurable para todas las especialidades. Puede acceder desde cualquier lugar, con solo una conexión a internet.',
          icon: eHistory,
          servicesFeatures: ['Reportes RIPS y de ley', 'Función de dictado']
    },
    {
          serviceTitle: 'Agenda de citas',
          description: 'Acceda desde cualquier dispositivo a su agenda de la semana y programe nuevas citas en cualquier momento.',
          icon: appointments,
          servicesFeatures: ['Recordatorio de citas vía  correo y SMS', 'Widget de agenda para su web']
    },
    {
          serviceTitle: 'Telemedicina',
          description: 'Brinde atención virtual a través de videoconferencia, sin que sus pacientes tengan que salir de la casa o la oficina.',
          icon: teleHealt,
          servicesFeatures: ['Servicio ilimitado', 'Función de grabación']
    },
    {
        serviceTitle: 'Prescripciones',
        description: 'Genere y exporte prescripciones de medicamentos, exámenes de laboratorio e incapacidades.',
        icon: prescripciones,
        servicesFeatures: ['Disponible la creación de formularios personalizados']
    },
    {
          serviceTitle: 'Facturación y finanzas',
          description: 'Siga de cerca el estado financiero de su consultorio o clínica y facture los servicios de todo tipo de clientes',
          icon: billingFacturing,
          servicesFeatures: ['Facture y cotice: prepagadas, convenios, copagos, cobros directos a pacientes.']
    },
    {
          serviceTitle: 'Gestión de inventarios',
          description: 'Disfrute de una potente solución de inventarios integrada a la historia clínica. ',
          icon: stock,
          servicesFeatures: ['Automatice el consumo de insumos y productos a medida que evoluciona a sus pacientes.']
    },
]

const ProductServiceFlexList: FC = () => <section className="product-service-list-section">
                                                    <div className='product-service-list__title-container'>
                                                        <h2 className='product-service-list__title-container-title'>
                                                          Ahorre tiempo y<br/> céntrese en el cuidado
                                                        </h2> 
                                                        <p className='product-service-list__title-container-description'>
                                                            eClinic le brinda todas las herramientas que necesita para <br />atender sus pacientes y administrar mejor su consultorio.
                                                        </p> 
                                                      {/*   <div className='product-service-list__title-container-button'>
                                                            <Link 
                                                                className='product-service-list__title-container-button-content' 
                                                                to="/demoeclinic">
                                                                    Todos los Servicios
                                                            </Link>
                                                        </div>  */} 
                                                    </div>
                                                    { servicesDescriptions.map( description =>
                                                        <ProductServiceItem {...description} key={description.serviceTitle}>
                                                            <img className="product-service-list__title-container-content" src={ description.icon } alt={`ícono de ${description.serviceTitle}`} />  
                                                        </ProductServiceItem >    
                                                    ) }      
                                         </section>;


export default ProductServiceFlexList;
