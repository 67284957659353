import React, { FC } from 'react';
import './EClinicUserPreference.css';
import securityIcon from "../../assets/icons/secury.svg";
import support from "../../assets/icons/update_support.svg";
import availibility from "../../assets/icons/availibility.svg";

const EClinicUserPreference: FC = () => <section className='user-preference-us-section'>
                                            <div className='user-preference-us-container'> 
                                                <h4 className='user-preference-us__title'>¿Por qué nos prefieren?</h4>
                                                <div className='user-preference_feature-container'>
                                                <article className='user-preference_feature'>
                                                    <div className='user-preference_feature-icon'>
                                                        <img  className="user-preference_feature-icon-img" src={securityIcon} alt="ícono de seguridad y confidencialidad"/>
                                                    </div>            
                                                    <h3>Seguridad y confidencialidad</h3>
                                                    <p>Cumplimiento de altos estándares de seguridad. Almacenamiento seguro y confidencial del historial clínico.</p>
                                                </article>
                                                <article className='user-preference_feature'>
                                                    <div className='user-preference_feature-icon'> 
                                                        <img className="user-preference_feature-icon-img"  src={availibility} alt="ícono de disponibilidad del 99.9%"/>
                                                    </div>
                                                    <h3>Disponibilidad del 99.9%</h3>
                                                    <p>Tendrá la tranquilidad de contar con una solución tecnológica estable, con un 99.9% de tiempo al aire.</p>
                                                </article>
                                                <article className='user-preference_feature'>
                                                    <div className='user-preference_feature-icon'>
                                                        <img className="user-preference_feature-icon-img"  src={support} alt="ícono de actualización y respaldo"/>
                                                    </div>
                                                        <h3>Actualización y respaldo</h3>
                                                        <p>Recibirá actualizaciones y mejoras de la plataforma. Nuestro soporte telefónico y web le permitirá sentirse respaldado.</p>
                                                    </article>
                                                </div>
                                            </div>    
                                        </section>;

export default EClinicUserPreference;                                        