import React, { FC, useEffect, useState } from 'react';
import "./TalkToExpert.css";
import ReactPixel from 'react-facebook-pixel';
import { Link } from 'react-router-dom';

const TalkToExpert: FC = () => { 


                              const [backButtonVisibility, setBackButtonVisibility] = useState<boolean>(false);
                              useEffect(() => {
                                    const script = document.createElement('script');
                                    script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
                                    script.async = true; 
                                    document.body.appendChild(script);

                                    window.addEventListener('message', handler);

                                    return () => {
                                          window.removeEventListener('message', handler);
                                          document.body.removeChild(script);
                                    }
                               }, []);

                               function handler(event: MessageEvent) {
                                    console.log(event);
                                    if (event.data.meetingBookSucceeded) {
                                          ReactPixel.track("Lead");
                                          setBackButtonVisibility( true )       
                                    }  
                              }           

                               return (
                                    <section className='talk-to-expert-container'>
                                          <div className="meetings-iframe-container" data-src="https://meetings.hubspot.com/doctor-help?embed=true"/> 
                                          { backButtonVisibility &&
                                           <Link to="/"
                                                 className='talk-to-expert__back_button-link'>
                                                <div className='talk-to-expert__back_button'>
                                                      <p>Regresar</p>
                                                </div>
                                           </Link>
                                          }   
                                       </section>
                                    )
                                }
                                       
export default TalkToExpert; 

