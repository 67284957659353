import React, {useState } from 'react';
import './App.css';
import Header from './components/header/Header';
import MainPage from './pages/MainPage';
import Features from './components/features/Features';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import ScheduleDemoEclinic from './components/scheduled-demo/ScheduleDemoEclinic'
import { Redirect, Route, Switch } from 'react-router';
import ProductServiceFlexList from './components/eclinic_product-services-flex-list/ProductServicesFlexList';
import TalkToExpert from './components/talk-to-expert/TalkToExpert';
import FrequentQuestions from "./components/frequent-questions/FrequentQuestions";
import ReactPixel, { AdvancedMatching } from 'react-facebook-pixel';
import EpaycoResponsePage from './components/epayco-response-page/EpaycoResponsePage';
import GA4React from "ga-4-react";
import CookieBanner from './components/cookies-banner/CookieBanner';
import CookiePolicy from "./components/cookie-policy/CookiePolicy";

const ga4react = new GA4React("G-HV86MG1D9D");
ga4react.initialize()

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};

const advancedMatching = {
/*   em: "cirs@hotmali.com" */
} as AdvancedMatching;


ReactPixel.init( "512944236458993", advancedMatching, options );
ReactPixel.pageView();
ReactPixel.revokeConsent();

//const scriptCallPage = `<script>var loadBabel = function(url, callback) {var script = document.createElement('script');script.async = true;if (script.readyState) {script.onreadystatechange = function() {if (script.readyState == 'loaded' || script.readyState == 'complete') {script.onreadystatechange = null;callback(window, document);}};} else {script.onload = function() {callback(window, document);};}script.src = url;document.head.appendChild(script);};var getCookie = function(cname) {var objToday = new Date();var version = objToday.toISOString().split('T')[0].split('-').join('');var name = cname + '=';var decodedCookie = decodeURIComponent(document.cookie);var cookieArray = decodedCookie.split(';');for (var i = 0; i < cookieArray.length; i++) {var cookie = cookieArray[i];cookie = cookie.trim();if (cookie.indexOf(name) == 0) {return cookie.substring(name.length, cookie.length);}}return version;};var loadWidget = function(window, document) {var _cp = {"id":"aXpFpvzmMdoGxLa6FT7LfK61BCMLzDzVnNLfqulC3Ks","version":"1.1"};var cp = document.createElement('script');cp.type = 'text/javascript';cp.async = true;cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.') + '?v=' + getCookie('callpage-widget-version');var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(cp, s);if (window.callpage) {alert('You could have only 1 CallPage code on your website!');} else {window.callpage = function(method) {if (method == '_getQueue') {return this.methods;} else if (method) {if (typeof window.callpage.execute === 'function') {return window.callpage.execute.apply(this, arguments);} else {(this.methods = this.methods || []).push({arguments: arguments,});}}};window.callpage.__cp = __cp;window.callpage('api.button.autoshow');}};loadBabel('https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js', function() {return loadWidget(window, document);});</script>`

function App() {

  const [isCookiesAccepted, setIsCookieAccepted] = useState<boolean>(false);

  const toggleCookieAccept = () => {
    if( isCookiesAccepted ) ReactPixel.grantConsent()
    setIsCookieAccepted( !isCookiesAccepted )
  }
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route
          exact = {true}
          path="/"
          component={MainPage} >  
        </Route>
        <Route
          path="/demoeclinic"
          component={ScheduleDemoEclinic} >  
        </Route>
        <Route
          path="/features"
          component={Features} >  
        </Route>
        <Route
          path="/contact"
          component={Contact} >  
        </Route>
        <Route
          path="/service_list"
          component={ProductServiceFlexList} > 
        </Route>
       
        <Route
          path="/talk_to_expert"
          component={TalkToExpert} > 
        </Route> 
        <Route
          path="/frequent-questions"
          component={FrequentQuestions} > 
        </Route>
        <Route
          path="/epayco_response"
          component={EpaycoResponsePage}>
        </Route>
       { /*<Route
          path="/cookie_policy"
          component={CookiePolicy}> 
       </Route>*/}
        <Redirect to="/" /> 
      </Switch>
      { /*!isCookiesAccepted && <CookieBanner acceptCookieListener={toggleCookieAccept} />*/}
      <Footer />
    </div>
  );
}

export default App;


/* var loadBabel = function(url, callback) { 
  var script = document.createElement('script');
  script.async = true;if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState == 'loaded' || script.readyState == 'complete') {
        script.onreadystatechange = null;
        callback(window, document);}};} 
        else {
          script.onload = function() {
            callback(window, document);};}
            script.src = url;document.head.appendChild(script);};
            var getCookie = function(cname) 
            {var objToday = new Date();
              var version = objToday.toISOString().split('T')[0].split('-').join('');
              var name = cname + '=';
              var decodedCookie = decodeURIComponent(document.cookie);
              var cookieArray = decodedCookie.split(';');
              for (var i = 0; i < cookieArray.length; i++) {
                var cookie = cookieArray[i];cookie = cookie.trim();
                if (cookie.indexOf(name) == 0) {
                  return cookie.substring(name.length, cookie.length);}}
                  return version;};
                  var loadWidget = function(window, document) {
                    var _cp = {"id":"aXpFpvzmMdoGxLa6FT7LfK61BCMLzDzVnNLfqulC3Ks","version":"1.1"};
                    var cp = document.createElement('script');
                    cp.type = 'text/javascript';
                    cp.async = true;
                    cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.') + '?v=' + getCookie('callpage-widget-version');
                    var s = document.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(cp, s);
                    if (window.callpage) {
                      alert('You could have only 1 CallPage code on your website!');
                    } else {window.callpage = function(method) {
                      if (method == '_getQueue') {
                        return this.methods;} 
                        else if (method) {
                          if (typeof window.callpage.execute === 'function') {
                            return window.callpage.execute.apply(this, arguments);} else {
                              (this.methods = this.methods || []).push({arguments: arguments,});}}};
                              window.callpage.__cp = _cp;
                              window.callpage('api.button.autoshow');}};
                              loadBabel('https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js', 
                              function() {return loadWidget(window, document);}); */




