import React, { useEffect, useState, createContext, useContext } from 'react';

type MediasQueries = { [mediaQuery:string]: string };
type MediaQueryMatch = { [media:string]: boolean };

const defaultValue = {} as MediaQueryMatch;

const BreakPointContext = createContext(defaultValue);

const BreakpointProvider = ({children, queries}: { children: React.ReactNode, queries: MediasQueries}) => {
    const [queryMatch, setQueryMatch] = useState({} as MediaQueryMatch);

    useEffect(() => {
        const mediaQueryList = {} as  { [media: string]: MediaQueryList };
        const keys = Object.keys( queries );
        let isAttached = false;

        const handleMediaQueryListener = () => {
            const updatedMatches = keys.reduce( ( accumulate, currentMediaQuery ) => {
                  accumulate[currentMediaQuery] = !!(mediaQueryList[currentMediaQuery] && mediaQueryList[currentMediaQuery].matches); 
                  return accumulate; 
            }, {} as MediaQueryMatch  );
            setQueryMatch(updatedMatches);
        };

        if( window ) {
            const matches = {} as MediaQueryMatch;
            for( const media of keys ) {
              if( typeof queries[media] === "string" ) {
                mediaQueryList[media] = window.matchMedia( queries[ media ] );
                matches[ media ] = mediaQueryList[media].matches;        
              } else {
                  matches[ media ] = false;
              }    
            };
            setQueryMatch(matches);
            console.log(matches);
            
            isAttached = true;
            keys.forEach( media => {
                            if( typeof queries[media] === "string" ) {
                                mediaQueryList[media]?.addEventListener("change", handleMediaQueryListener );
                            }
                        } 
             );
        };
    return () => {
        if( isAttached ) {
            keys.forEach( media => {
                if(typeof queries[media] === 'string') {
                    mediaQueryList[media].removeEventListener( "change", handleMediaQueryListener );
                  }    
            } );
        }
     }           
    }, [queries] );

    return ( 
        <BreakPointContext.Provider value={queryMatch} >
            {children}
        </BreakPointContext.Provider>
     );
}

const useBreakPoint = () => {
    const context = useContext(BreakPointContext);
    if(context === defaultValue) {
        throw new Error('useBreakpoint must be used within BreakpointProvider');
      }
      return context;
 }

export {useBreakPoint, BreakpointProvider } ;